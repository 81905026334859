//
// Layouts
//

// Navbar

.navbar {
	position: sticky;
	top: 0;
	color: $white;
	background-color: $indigo;
	z-index: $zindex-sticky;
	
	.navbar-brand {
		color: $white;
	}
}

.navbar-nav {
	.nav-item {
		border-bottom: solid $border-width rgba($white, .25);
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.nav-link {
		&:not(:hover),
		&:not(:focus) {
			color: $white;
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar {
		> .container,
		> .container-fluid {
			flex-wrap: inherit;
		}
		
		.navbar-brand {
			margin-left: 0;
			padding-left: 0;
		}
	}
	
	.navbar-toggler {
		display: none;
	}
	
	.navbar-collapse {
		display: flex !important;
		flex-basis: auto;
		flex-grow: inherit;
		padding-left: 0;
		padding-right: 0;
	}
	
	.navbar-nav {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		
		.nav-item {
			border-bottom: 0;
		}
		
		.nav-link {
			position: relative;
			padding: $nav-link-padding-x $nav-link-padding-x * .5;
			
			&:hover,
			&:focus {
				color: $primary;
			}
			
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 0;
				height: 2px;
				background-color: $primary;
				@include transition($transition-base);
				transform: translateX(-50%);
			}
			
			&:hover::before,
			&:hover::after {
				width: 100%;
			}
		}
	}
}

// Footer

.footer {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
	color: $white;
	background-color: $indigo;
	@include font-size($font-size-sm);
	
	.navbar-brand {
		display: block;
		margin-bottom: $paragraph-margin-bottom * .5;
		color: $white;
	}
	
	.row {
		.section {
			@include make-col-ready();
		}
	}
	
	@include media-breakpoint-up(md) {
		.row {
			.section {
				margin-top: 0 !important;
				@include make-col(4, $grid-columns);
			}
		}
	}
}

.credit {
	padding-top: $paragraph-margin-bottom;
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width * .5;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}

// Content

.cover,
.colophon {
	background: $gray-200 url(../img/bg.png) repeat left top;
	
	img {
		width: 100%;
	}
}

.content,
.colophon {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
}
